<template>
  <div class="container no_touch">
    <!--  电子围栏  -->
    <div class="contr-map">
      <safe-map ref="map" v-if="center" :center="center" :radius="radius" :zoom="zoom"/>
    </div>
    <div>
      <div class="address">地址： {{ address }}</div>
      <div class="contr-radius">范围： {{ radius }}m
        <van-slider class="slider" v-model="radius" @change="onChange" max="5000" min="100" step="10"/>
        5km
      </div>
      <div class="contr-button">
        <van-button class="button" type="warning" @click="deleteGeofence" square>删除</van-button>
        <van-button class="button" type="primary" @click="showEditWindow" square>保存</van-button>
      </div>
    </div>

    <van-dialog v-model="show" @confirm="saveGeofence" show-cancel-button>
      <div class="contr-edit">
        <input class="input" v-model="fenceName" placeholder="输入安全区域名称">
        <div class="tip">安全区域报警方式</div>
        <van-radio-group v-model="alarmType" direction="horizontal">
          <van-radio name="1">进</van-radio>
          <van-radio name="2">出</van-radio>
          <van-radio name="3">进出</van-radio>
        </van-radio-group>
      </div>
    </van-dialog>
  </div>
</template>

<script>
import {post} from "@/router/httpUtils";
import {MapType} from "@/constants/contants";
import {Toast} from "vant";
import SafeMap from "@/components/SafeMap";

export default {
  name: "SafeArea",
  components: {SafeMap},
  data() {
    return {
      center: null,
      zoom: 14,
      BMap: null,
      map: null,
      show: false,

      address: '',
      alarmType: '3',
      fenceName: '',
      radius: 100,
    }
  },
  mounted() {
    this.radius = 1390
    this.center = {
      lng: 120.032554268109,
      lat: 30.3014914748654
    }
    this.address = '浙江省杭州市余杭区/离三家村(正东)约301米/离香樟树下(西南)约307米'
    this.fenceName = "安全区域"
    this.alarmType = '2'
  },
  methods: {
    handler({BMap, map}) {
      console.log(BMap, map)

      this.BMap = BMap
      this.map = map
      this.center = {
        lng: this.$route.params.Longitude,
        lat: this.$route.params.Latitude
      }
      console.log(this.center)
    },
    onChange(radius) {
      console.log(radius)
      this.radius = radius
    },
    showEditWindow() {
      this.show = true
    },
    saveGeofence() {

      if (this.$route.params.FenceId) {
        this.editGeofence()
      } else {
        this.createGeofence()
      }
    },
    createGeofence() {

      const params = {
        Item: {
          "Address": "浙江省杭州市余杭区/离三家村(正东)约301米/离香樟树下(西南)约307米",
          "AlarmModel": 0,
          "AlarmType": 2,
          "DeviceId": 2685832,
          "EndTime": "",
          "FenceId": 98562,
          "FenceName": "安全区域",
          "FenceType": 1,
          "InUse": true,
          "IsDeviceFence": false,
          "Latitude": 30.3014914748654,
          "Longitude": 120.032554268109,
          "Radius": 1390,
          "StartTime": "",
          "Token": localStorage.Token
        },
        MapType: MapType,
        Token: localStorage.Token
      }

      post('/api/Geofence/CreateGeofence', params)
          .then(res => {
            console.log(res);
            if (res.Message === "Success") {
              this.$router.back()
              Toast('创建安全区域成功～');
            } else {
              Toast('创建安全区域失败！');
            }
          })
          .catch(err => {
            console.log(err);
          })
    },
    editGeofence() {
      const params = {
        Item: {
          "Address": this.address,
          "AlarmType": this.alarmType,
          "DeviceId": localStorage.DeviceId,
          "EndTime": "",
          "FenceId": this.$route.params.FenceId,
          "FenceName": this.fenceName,
          "FenceType": 1,
          "InUse": true,
          "IsDeviceFence": false,
          "Latitude": this.center.lat,
          "Longitude": this.center.lng,
          "Radius": this.radius,
          "StartTime": "",
          "Token": localStorage.Token
        },
        MapType: MapType,
        Token: localStorage.Token
      }

      post('/api/Geofence/EditGeofence', params)
          .then(res => {
            console.log(res);
            if (res.Message === "Success") {
              this.$router.back()
              Toast('修改安全区域成功～');
            } else {
              Toast('修改安全区域失败！');
            }
          })
          .catch(err => {
            console.log(err);
          })
    },
    deleteGeofence() {
      const params = {
        "Address": this.address,
        "AlarmType": this.alarmType,
        "DeviceId": localStorage.DeviceId,
        "EndTime": "",
        "FenceId": this.$route.params.FenceId,
        "FenceName": this.fenceName,
        "FenceType": 1,
        "InUse": true,
        "IsDeviceFence": false,
        "Latitude": this.center.lat,
        "Longitude": this.center.lng,
        "Radius": this.radius,
        "StartTime": "",
        "Token": localStorage.Token
      }

      post('/api/Geofence/DeleteGeofence', params)
          .then(res => {
            console.log(res);
            if (res.Message === "Success") {
              this.$router.back()
              Toast('删除安全区域成功～');
            } else {
              Toast('删除安全区域失败！');
            }
          })
          .catch(err => {
            console.log(err);
          })
    }
  }
}
</script>

<style scoped>
.container {

}

.contr-map {
  font-size: 14px;
}

.map {
  width: 100%;
  height: calc(100vh - 124px);
  /*height: 10px;*/
}

.address {
  height: 38px;
  margin: 8px 8px 0px 8px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.contr-radius {
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 18px;
  justify-content: space-between;
  padding: 8px 8px 8px 8px;
  /*border-bottom: 1px solid gray;*/
}

.slider {
  width: 55%;
}

.contr-button {
  /*margin-top: 10px;*/
}

.button {
  width: 50%;
}

.contr-edit {
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: center;
  padding: 20px 20px;
  width: 100%;
}

.input {
  width: max-content;
}

.tip {
  margin-top: 10px;
  margin-bottom: 10px;
}

.overlay-circle {
}

.contr-overlay {
  position: absolute;
  top: 0;
}
</style>